<template>
  <v-container>
    <v-row>
      <p class="mb-2" style="font-weight: bold">Physical properties of obtained products:</p>
    </v-row>

    <v-row class="mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td rowspan="2" style="text-align: center; font-weight: bold">Product Name</td>
            <td colspan="2" style="text-align: center; font-weight: bold">Reaction Yield</td>
            <td rowspan="2" style="text-align: center; font-weight: bold">
              Yield of the pure product, g
            </td>
            <td rowspan="2" style="text-align: center; font-weight: bold">Color</td>
            <td rowspan="2" style="text-align: center; font-weight: bold">Crystal Form</td>
            <td rowspan="2" style="text-align: center; font-weight: bold">Wet/Dry</td>
            <td rowspan="2" style="text-align: center; font-weight: bold">Lit m.p. (°C)</td>
            <td rowspan="2" style="text-align: center; font-weight: bold">Practical m.p. (°C)</td>
          </tr>
          <tr>
            <td style="font-weight: bold; text-align: center">Theoretical, g</td>
            <td style="font-weight: bold; text-align: center">Crude g and %</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.productName1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.theoretical1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.crude1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.yieldPureProduct1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.color1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.crystalForm1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetDry1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.litMP1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.practicalMP1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <v-text-field
                v-model="inputs.productName2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.theoretical2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.crude2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.yieldPureProduct2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.color2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.crystalForm2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetDry2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.litMP2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.practicalMP2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>

    <v-row>
      <p class="mb-2" style="font-weight: bold">Ninhydrin Test:</p>
    </v-row>
    <v-row class="mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td rowspan="2" style="text-align: center; font-weight: bold">Substance</td>
            <td colspan="2" style="text-align: center; font-weight: bold">Colour</td>
          </tr>
          <tr>
            <td style="text-align: center; font-weight: bold">0 min</td>
            <td style="text-align: center; font-weight: bold">10 min</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Acetylglycine</td>
            <td>
              <v-text-field
                v-model="inputs.acetylglycineColour0Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.acetylglycineColour10Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Benzoylglycine</td>
            <td>
              <v-text-field
                v-model="inputs.benzoylglycineColour0Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.benzoylglycineColour10Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Glycine</td>
            <td>
              <v-text-field
                v-model="inputs.glycineColour0Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.glycineColour10Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Water</td>
            <td>
              <v-text-field
                v-model="inputs.waterColour0Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterColour10Min"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>

    <v-row>
      <p class="mb-2" style="font-weight: bold">
        Please obtain the IR spectra for your product and fill up the table below:
      </p>
    </v-row>
    <v-row class="mb-5">
      <v-simple-table>
        <tbody>
          <tr>
            <td style="text-align: left; font-weight: bold">Functional group</td>
            <td style="text-align: center; font-weight: bold">
              Experimental absorptions <stemble-latex content="$(\text{cm}^{-1})$" />
            </td>
            <td style="text-align: center; font-weight: bold">
              Literature IR absorptions <stemble-latex content="$(\text{cm}^{-1})$" />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup4"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions4"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions4"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup5"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions5"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions5"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup6"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions6"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions6"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup7"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions7"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions7"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup8"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions8"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions8"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup9"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions9"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions9"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.functionalGroup10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.experimentalAbsorptions10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.literatureIRAbsorptions10"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>

    <v-row>
      <p class="mb-2" style="font-weight: bold">
        Please analyze posted NMR and LC-MS data and summarize it in a table below:
      </p>
    </v-row>
    <v-row class="mb-5">
      <v-simple-table>
        <thead>
          <td style="text-align: center; font-weight: bold">Compound</td>
          <td style="text-align: center; font-weight: bold">
            IR-spectrum,
            <stemble-latex content="$\gamma~(\text{cm}^{-1})$" />
          </td>
          <td style="text-align: center; font-weight: bold">
            <stemble-latex content="$^1\text{H}$" />
            NMR-spectrum,
            <stemble-latex content="$\delta~\text{(ppm),}$" />
            SSCC, <stemble-latex content="$\text{J (Hz)}$" />
          </td>
          <td style="text-align: center; font-weight: bold">
            Molecular ion,
            <stemble-latex content="$m/z~(I_l,~\%$)" />
          </td>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.compound1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.IRSpectrum1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.NMRSpectrum1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.moleculization1"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.compound2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.IRSpectrum2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.NMRSpectrum2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.moleculization2"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <v-text-field
                v-model="inputs.compound3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.IRSpectrum3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.NMRSpectrum3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.moleculization3"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-row>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'ChemUTS47E2Datasheet',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        productName1: null,
        productName2: null,
        theoretical1: null,
        theoretical2: null,
        crude1: null,
        crude2: null,
        yieldPureProduct1: null,
        yieldPureProduct2: null,
        color1: null,
        color2: null,
        crystalForm1: null,
        crystalForm2: null,
        wetDry1: null,
        wetDry2: null,
        litMP1: null,
        litMP2: null,

        acetylglycineColour0Min: null,
        acetylglycineColour10Min: null,
        benzoylglycineColour0Min: null,
        benzoylglycineColour10Min: null,
        glycineColour0Min: null,
        glycineColour10Min: null,
        waterColour0Min: null,
        waterColour10Min: null,

        functionalGroup1: null,
        functionalGroup2: null,
        functionalGroup3: null,
        functionalGroup4: null,
        functionalGroup5: null,
        functionalGroup6: null,
        functionalGroup7: null,
        functionalGroup8: null,
        functionalGroup9: null,
        functionalGroup10: null,

        experimentalAbsorptions1: null,
        experimentalAbsorptions2: null,
        experimentalAbsorptions3: null,
        experimentalAbsorptions4: null,
        experimentalAbsorptions5: null,
        experimentalAbsorptions6: null,
        experimentalAbsorptions7: null,
        experimentalAbsorptions8: null,
        experimentalAbsorptions9: null,
        experimentalAbsorptions10: null,

        literatureIRAbsorptions1: null,
        literatureIRAbsorptions2: null,
        literatureIRAbsorptions3: null,
        literatureIRAbsorptions4: null,
        literatureIRAbsorptions5: null,
        literatureIRAbsorptions6: null,
        literatureIRAbsorptions7: null,
        literatureIRAbsorptions8: null,
        literatureIRAbsorptions9: null,
        literatureIRAbsorptions10: null,

        compound1: null,
        compound2: null,
        compound3: null,
        IRSpectrum1: null,
        IRSpectrum2: null,
        IRSpectrum3: null,
        NMRSpectrum1: null,
        NMRSpectrum2: null,
        NMRSpectrum3: null,
        moleculization1: null,
        moleculization2: null,
        moleculization3: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid black;
  padding: 4px;
  text-align: center;
}
</style>
